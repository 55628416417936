import * as React from 'react';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import { IDynamicBlocBaseProps, DynamicBloc } from '../common';
import { ContentPageLink } from '../../contentpagelink';

import './bloc.eventinfos.less';

export class BlocEventInfos extends React.PureComponent<IDynamicBlocBaseProps, any> {
    render() {
        const links = this.props.template.properties.links as VisualTheme.IContentPageLink[];
        let linksElts;
        if (links) {
            linksElts = links.map((l, idx) => {
                return <ContentPageLink {...this.props} key={"link" + idx} link={l} />;
            });
        }

        return <DynamicBloc {...this.props}>
            <div className="bloc-content">
                {linksElts}
            </div>
        </DynamicBloc>;
    }
}
