import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { translateBag } from '@inwink/i18n/utils/translate';
import { IDynamicBlocBaseProps, DynamicBloc } from '../common';
import { BlocContent } from '../common.title';
import { getTargetPage } from '../../navigation';
import type { IInwinkStore } from '../../../store';
import { useStore } from 'react-redux';
import './bloc.teaserbanner.less';

interface IBlocTeaserBannerComponentProps extends IDynamicBlocBaseProps {
    history?: any;
    store?: IInwinkStore;
}

class BlocTeaserBannerComponent extends React.PureComponent<IBlocTeaserBannerComponentProps, any> {
    render() {
        // if (this.props.template.properties && (this.props.template.properties.requireConnexion === true
        //     || this.props.template.properties.requireConnexion === false
        //     || this.props.template.properties.requireRegistration === true
        //     || this.props.template.properties.requireRegistration === false)) {
        //     if ((global as any).disableAuthenticatedFeatures) {
        //         return <div className="empty" />;
        //     }

        //     if (displayContent(this.props.user, {
        //         event: this.props.event,
        //         community: this.props.community,
        //         requireConnexion: this.props.template.properties.requireConnexion,
        //         requireRegistration: this.props
        //     } ,
        //         this.props.template.properties.requireRegistration) === false) return <div className="empty" />;
        // }

        let icon;
        let content;
        let action;
        let contentStyle;

        if (this.props.template.properties && this.props.template.properties.icon) {
            if (!this.props.template.properties.icon.url) {
                icon = <i
                    className={this.props.template.properties.icon.className}
                    style={this.props.template.properties.icon.style}
                />;
            } else {
                icon = <img
                    alt=""
                    src={this.props.template.properties.icon.url}
                    className={this.props.template.properties.icon.className}
                    style={this.props.template.properties.icon.style}
                />;
            }
        }

        if (this.props.template.properties && this.props.template.properties.text) {
            content = <h5
                className="content"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={
                    {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        __html: translateBag(this.props.i18n, this.props.template.properties.text)
                    }
                }
            />;
        }

        if (this.props.template.properties && this.props.template.properties.style) {
            contentStyle = this.props.template.properties.style;
        }

        if (this.props.template.properties && this.props.template.properties.action) {
            let text = "";
            if (this.props.template.properties.action.title) {
                text = translateBag(this.props.i18n, this.props.template.properties.action.title);
            }

            let onClick;
            if (this.props.template.properties.action.link) {
                let link = this.props.template.properties.action.link;
                if (typeof this.props.template.properties.action.link === 'string') {
                    link = {
                        target: this.props.template.properties.action.link
                    };
                }

                if (link && link.url) {
                    onClick = () => {
                        window.open(this.props.template.properties.action.link.url);
                    };
                } else if (link) {
                    const linkTarget = getTargetPage(
                        this.props.template.properties.action.link,
                        this.props.page.context,
                        this.props.store
                    );

                    if (typeof linkTarget === "string") {
                        onClick = () => {
                            this.props.history.push(this.props.urlservice.pageUrl(linkTarget));
                        };
                    } else if (typeof linkTarget === "function") {
                        onClick = () => {
                            linkTarget();
                        };
                    }
                }
            }

            if (onClick) {
                action = <button type="button" className="clickable" onClick={onClick.bind(this)}>{text}</button>;
            } else {
                action = <button type="button">{text}</button>;
            }
        }

        return <DynamicBloc
            {...this.props}
            className={"bloc-teaserbanner " + ((this.props.template as any).className
                ? (this.props.template as any).className : "")}
        >
            <BlocContent {...this.props} style={contentStyle}>
                {icon}
                {content}
            </BlocContent>
            <div className="bloc-action">
                {action}
            </div>
        </DynamicBloc>;
    }
}

function BlocTeaserBannerCtrl(props: IBlocTeaserBannerComponentProps) {
    const store = useStore() as any;
    return <BlocTeaserBannerComponent {...props} store={store} />;
}

export const BlocTeaserBanner: new (props: IDynamicBlocBaseProps)
=> React.Component<IDynamicBlocBaseProps, any> = withRouter(BlocTeaserBannerCtrl as any) as any;
