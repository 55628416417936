import { Entities } from '@inwink/entities/entities';
import { getPredicate } from '@inwink/expressions';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import * as React from 'react';
import type { States } from '../../../services/services';
import { displayContent, getItemsProperties } from '../../../services/itemshelpers';
import { ContentPageLink } from '../../contentpagelink';
import { DynamicBloc, IDynamicBlocProps } from '../common';
import { BlocTitle, BlocContent } from "../common.title";
import { checkIfShownByDate } from '../helpers';
import './bloc.teasereventinfos.less';

interface IBlocTeaserEventInfosProps extends IDynamicBlocProps<IBlocTeaserEventInfosState> {
    i18nHelper?: Entities.i18nHelper;
}

export interface IBlocTeaserEventInfosState {
    itemsProp?: any;
}

@withI18nHelper()
export class BlocTeaserEventInfos extends React.Component<IBlocTeaserEventInfosProps, IBlocTeaserEventInfosState> {
    container = React.createRef<any>();

    constructor(props: IBlocTeaserEventInfosProps) {
        super(props);
        this.state = {
            itemsProp: getItemsProperties(
                props.template.id,
                props.visualstate,
                props.template.properties,
                props.template,
                "center",
                "col2")
        };
    }

    filterItem = (it, event: States.IEventState, datacontext: Entities.IPageDataContext) => {
        const item = it;
        let show;
        show = true;
        if (item.disabled) {
            show = false;
        }
        if (item.showIf) {
            if (!(item as any).showIfPredicate) {
                const pr = getPredicate(item.showIf);
                (item as any).showIfPredicate = pr;
            }
            show = (item as any).showIfPredicate(this.props.datacontext);
        }

        if (item.showblocbydate && event.detail && !InWinkPreview) {
            show = checkIfShownByDate(item, event, datacontext);
        }

        if (show) {
            show = displayContent(this.props.user, {
                event: this.props.event,
                community: this.props.community,
                requireConnexion: item.requireConnexion,
                requireRegistration: item.requireRegistration,
                requireMembership: item.requireMembership,
                membershipActive: item.membershipActive,
                membershipLevelIds: item.membershipLevelIds
            });
        }

        return show;
    };

    componentDidUpdate(prevProps: IBlocTeaserEventInfosProps) {
        if (prevProps.visualstate !== this.props.visualstate) {
            this.setState({
                itemsProp: getItemsProperties(
                    this.props.template.id,
                    this.props.visualstate,
                    this.props.template.properties,
                    this.props.template,
                    "center",
                    "col2")
            });
        }
    }

    render() {
        const itemsLayout = this.state.itemsProp.itemsLayout;
        const itemsAlign = this.state.itemsProp.itemsAlign;
        const links = this.props.template.properties.links;
        let linksElts;
        if (links) {
            linksElts = links.filter((l) => this.filterItem(l, this.props.event, this.props.datacontext))
                .map((l, idx) => {
                    const className = ["eventinfoslnk", "inwink-item"];
                    let title;
                    const i18n = this.props.i18nHelper;
                    if (l.showblocbydate && InWinkPreview) {
                        const filterType = checkIfShownByDate(l, this.props.event, this.props.datacontext, true);
                        if (filterType) {
                            className.push("hidden");
                            if ((filterType as any).dates) {
                                title = i18n.translate("actions.filterbydate.dates", { dates: (filterType as any).dates });
                            } else {
                                title = i18n.translate(`actions.filterbydate.${filterType}`);
                            }
                        }
                    }
                    if (l?.viewports?.length) {
                        className.push("has-viewport");
                        l.viewports.forEach((viewport) => {
                            className.push(`display-on-${viewport}`);
                        });
                    }
                    return <ContentPageLink
                        title={title}
                        {...this.props}
                        key={"link" + idx}
                        link={l}
                        className={className.join(" ")}
                    />;
                });
        }

        const classNames = "bloc-content contentpagelink-items inwink-items layout-" + itemsLayout + " align-" + itemsAlign + " "
            + this.state.itemsProp.itemsLayoutClassName;
        if (this.container?.current) {
            if (classNames !== this.container.current.className) {
                this.container.current.className = classNames;
            }
        }

        return <DynamicBloc {...this.props}>
            <BlocTitle {...this.props} className="bloc-header" />
            <BlocContent
                {...this.props}
                parentRef={this.container}
                contentClassName={classNames}
            >
                {linksElts}
            </BlocContent>
        </DynamicBloc>;
    }
}
