import * as React from 'react';
import * as assign from 'lodash/assign';
import { connect } from 'react-redux';
import type { IPopoverManager } from '@inwink/modals';
import { bindActionCreators } from 'redux';
import { getPredicate } from '@inwink/expressions';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { displayContent } from '@@services/itemshelpers';
import type { States } from '@@services/services';
import { checkIfShownByDate } from '../helpers';
import { MenuItem } from './bloc.menuitems.item';

import './bloc.menuitems.less';

export interface IMenuItem extends VisualTheme.IContentPageLink {
    requireConnexion?: boolean;
    requireRegistration?: boolean;
    requireMembership?: boolean;
    membershipActive?: boolean;
    showIf?: Entities.Expression[];
    showIfPredicate?: any;
    showblocbydate?: string;
    displayOptions?: VisualTheme.IBlocTemplateActionDisplayOptions;
    disabled?: boolean;
    items?: IMenuItem[];
}

export interface IBlocMenuItemsProps /* extends IDynamicBlocBaseProps */ {
    event?: States.IEventState;
    community?: States.ICommunityState;
    rootwebsite?: States.IRootWebsiteState;
    user?: States.IAppUserState;
    i18n?: States.i18nState;
    metadataMenuActions?: typeof metadataMenuActions;
    template: { properties: { items: IMenuItem[]; itemStyle: any; itemClassName: string; inherit: string; }};
    theme: VisualTheme.IBlocTheme;
    pages?: any;
    visualConfiguration?: any;
    datacontext: Entities.IPageDataContext;
    popovermgr?: IPopoverManager;
    i18nHelper?: Entities.i18nHelper;
    isAppHeader?: boolean;
    items?: IMenuItem[];
    isSubMenu?: boolean;
    showSubMenuIcon?: boolean;
    location: States.ILocation;
    onItemClicked?: () => void;
    menuId?: string;
}

export interface IBlocMenuItemsState {
    items: IMenuItem[];
}

@withI18nHelper()
class BlocMenuItemsComponent extends React.PureComponent<IBlocMenuItemsProps, IBlocMenuItemsState> {
    constructor(props: IBlocMenuItemsProps) {
        super(props);

        this.state = {
            items: this.getMenuItems(props)
        };
    }

    getMenuItems(props: IBlocMenuItemsProps) {
        const items: IMenuItem[] = [];
        if (props.template?.properties?.inherit) {
            let headerTemplate = null;
            if (props.event?.eventid) {
                headerTemplate = props.event.data.templates.data.find(
                    (t) => t.application === "companion" && t.tinyUrl === props.template.properties.inherit
                );
            }
            if (props.community?.communityid) {
                headerTemplate = props.community.data.templates.data.find(
                    (t) => t.application === "community" && t.tinyUrl === props.template.properties.inherit
                );
            }

            if (headerTemplate) {
                headerTemplate.config?.blocs?.forEach((b) => {
                    b.content?.forEach((c) => {
                        if (c.type === "menuitems" && c.properties?.items?.length) {
                            items.push(...c.properties.items);
                        }
                    });
                });
            }
        }

        if (this.props.template?.properties?.items.length) {
            items.push(...this.props.template.properties.items);
        }

        return items;
    }

    componentDidUpdate(prevprops: IBlocMenuItemsProps) {
        if (this.props.event !== prevprops.event || this.props.community !== prevprops.community) {
            this.setState({
                items: this.getMenuItems(this.props)
            });
        }
    }

    itemClicked() {
        if (this.props.onItemClicked) {
            this.props.onItemClicked();
        }
        this.props.metadataMenuActions.showMenu(false);
    }

    render() {
        let itemElts;
        let items;
        if (this.props.isSubMenu) {
            items = this.props.items;
        } else {
            items = (this.props.template?.properties?.items ? this.props.template.properties.items : null) as IMenuItem[];
        }
        if (items && items.length) {
            itemElts = items.filter((m) => {
                return !m.disabled
                    && displayContent(this.props.user, {
                        event: this.props.event,
                        community: this.props.community,
                        requireConnexion: m.requireConnexion,
                        requireRegistration: m.requireRegistration,
                        requireMembership: m.requireMembership,
                        membershipActive: m.membershipActive,
                        membershipLevelIds: m.membershipLevelIds
                    })
                    && this.filterItemMenu(m)
                    && (!InWinkPreview && this.props.event?.eventid
                        ? checkIfShownByDate(m, this.props.event, this.props.datacontext)
                        : true);
            }).map((m, idx) => {
                let tabIndex;
                const className = [];
                if (this.props.isSubMenu) {
                    if (this.props.isAppHeader) {
                        tabIndex = idx + 1;
                    } else {
                        tabIndex = 0;
                    }
                }
                if (m?.viewports?.length) {
                    className.push("has-viewport");
                    m.viewports.forEach((viewport) => {
                        className.push(`display-on-${viewport}`);
                    });
                }
                const visualOptionsClassname = m.displayOptions && m.displayOptions.isSecondary ? 'lightbtn' : null;
                // handle color for active item in header
                
                const primaryColorBloc = this.props.visualConfiguration?.blocThemes?.find(bloc => bloc.id === 'primarycolorbloc');
                const primaryAccentColor = primaryColorBloc?.accent?.color;

                const active = (
                    m.link && this.props.pages && this.props.pages?.currentPageId &&
                    ((!!m.link?.target && m.link?.target === this.props.pages?.currentPageId) ||
                        (!!m.link?.content && m.link?.content === this.props.pages?.currentPageId) ||
                        (!!m.link?.tinyUrl && m.link?.tinyUrl === this.props.pages?.currentPage?.tinyurl) ||
                        ((!!m.link?.tinyUrl && this.props.pages?.currentPageId?.includes(m.link?.tinyUrl)))
                    ));
                
                let style = null;

                if (active) {
                    if (this.props.theme?.activeColor && this.props.theme?.accent?.color) {
                        style = assign({}, this.props.template?.properties.itemStyle, {
                            color: this.props.theme.accent.color,
                        });
                    } else if ((!this.props.theme?.accent && !this.props.theme?.colors) 
                            && ((this.props.theme && '_shouldBeDeleted' in this.props.theme) || !this.props.theme)) {
                        // #54920 cas spécifique navigation mobile
                        style = assign({}, this.props.template?.properties.itemStyle, {
                            color: primaryAccentColor ? 
                                primaryAccentColor : this.props.visualConfiguration?.defaultTheme?.accentBg?.color,
                        });
                    } else {
                        style = this.props.template?.properties.itemStyle;
                    }
                } else {
                    style = this.props.template?.properties.itemStyle;
                }

                return <MenuItem
                    key={"item" + idx}
                    style={style}
                    event={this.props.event}
                    rootwebsite={this.props.rootwebsite}
                    community={this.props.community}
                    user={this.props.user}
                    i18n={this.props.i18n}
                    isAppHeader={this.props.isAppHeader}
                    showSubMenuIcon={this.props.showSubMenuIcon}
                    metaDataMenuActions={this.props.metadataMenuActions}
                    page={this.props.pages?.currentPage}
                    tabIndex={tabIndex}
                    location={this.props.location}
                    datacontext={this.props.datacontext}
                    i18nHelper={this.props.i18nHelper}
                    className={(this.props.template?.properties.itemClassName
                        + (this.props.isAppHeader && this.props.isSubMenu ? " submenu" : ""))
                        + (visualOptionsClassname ? ' ' + visualOptionsClassname : '') + ' ' + className.join(" ")}
                    item={m}
                    itemIndex={idx}
                    menuId={this.props.menuId}
                    onClick={() => this.itemClicked()}
                />;
            });
        }
        return <div className="links-list">{itemElts}</div>;
    }

    private filterItemMenu(_item) {
        const item = _item;
        if (item.showIf) {
            if (!(item as any).showIfPredicate) {
                const pr = getPredicate(item.showIf);
                (item as any).showIfPredicate = pr;
            }
            return (item as any).showIfPredicate(this.props.datacontext);
        }
        return true;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        metadataMenuActions: bindActionCreators(metadataMenuActions, dispatch),
    };
}

function mapStateToProps(state: States.IAppState) {
    return {
        user: state.user,
        event: state.event,
        community: state.community,
        i18n: state.i18n,
    };
}

export const BlocMenuItemsControl: new (any) => React.Component<IBlocMenuItemsProps, any> = connect(
    mapStateToProps,
    mapDispatchToProps
)(BlocMenuItemsComponent as any) as any;

// eslint-disable-next-line import/no-mutable-exports
export let BlocMenuItems = BlocMenuItemsControl;

export function hookMenuItemsBloc(callback) {
    BlocMenuItems = callback(BlocMenuItemsControl);
}
