import * as React from 'react';
import * as assign from 'lodash/assign';
import { DynLabel, AppLabel } from '../../services/i18nservice';
import { SocialLinks } from '../sociallinks';
import { IDynamicBlocBaseProps } from './definitions';
import { BlocActions } from './common.actions';
import { withI18nHelper } from '@inwink/i18n';
import type { Entities } from '@inwink/entities/entities';
import { InwinkImage } from '@@components/images';

@withI18nHelper()
class BlocTitleControl extends React.PureComponent<IDynamicBlocBaseProps & {i18nHelper?: Entities.i18nHelper}, any> {
    htmlRef = React.createRef<HTMLHeadingElement>();

    componentDidMount() {
        if (this.htmlRef.current) {
            this.htmlRef.current.addEventListener("paste", this.pasteContent);
        }
    }

    pasteContent = (arg) => {
        const clipboardData = arg.clipboardData;
        if (clipboardData) {
            const clipboardText = clipboardData.getData('text/plain');
            arg.preventDefault();
            document.execCommand("insertHTML", false, clipboardText);
        }
    };

    componentWillUnmount() {
        if (this.htmlRef.current) {
            this.htmlRef.current.removeEventListener("paste", this.pasteContent);
        }
    }

    patchHeader(patch) {
        if (!this.props.header && this.props.template.header) {
            const header = assign({}, this.props.template.header, patch);
            const template = assign({}, this.props.template, { header: header });
            this.props.patchContent(template);
        }
    }

    patchSocialLinks(patch) {
        const socialLinks = assign({}, this.props.template.socialLinks, patch);
        const template = assign({}, this.props.template, { socialLinks: socialLinks });
        this.props.patchContent(template);
    }

    patchAction(patch) {
        const template = assign({}, this.props.template, patch);
        this.props.patchContent(template);
    }

    render() {
        let title; let subtitle; let legend; let description; let smalltitle; let actionsItems; let picture; let
            socialLinksItems; let icon;
        const header = this.props.header || this.props.template.header;
        const actions = this.props.actions || this.props.template.actions;
        const socialLinks = this.props.socialLinks || this.props.template.socialLinks;
        const theme = this.props.theme || {} as any;

        if (!header && !actions && !socialLinks) {
            return null;
        }

        if (header?.icon) {
            icon = <i className={"headericon " + (header.icon.className || "")} style={header.icon.style} />;
        }

        if (header && (header.preTitle || header.title)) {
            let blocpretitle; let
                bloctitle;
            if (header.preTitle) {
                if (typeof header.preTitle === "string") {
                    blocpretitle = <AppLabel
                        component="span"
                        style={theme.pretitle}
                        className="text pretitle bloc-accent"
                        i18n={header.preTitle}
                    />;
                } else {
                    blocpretitle = <DynLabel
                        component="span"
                        style={theme.pretitle}
                        className="text pretitle bloc-accent"
                        displayLanguage={this.props.i18n.currentLanguageCode}
                        i18n={header.preTitle}
                        editable={this.props.editable}
                        onEdit={this.props.editable ? (text) => { this.patchHeader({ preTitle: text }); } : null}
                    />;
                }
            }

            if (header.title) {
                if (typeof header.title === "string") {
                    bloctitle = <AppLabel component="span" style={theme.title} className="text bloctitle" i18n={header.title} />;
                } else {
                    bloctitle = <DynLabel
                        component="span"
                        style={theme.title}
                        displayLanguage={this.props.i18n.currentLanguageCode}
                        className="text bloctitle"
                        i18n={header.title}
                        editable={this.props.editable}
                        onEdit={this.props.editable ? (text) => { this.patchHeader({ title: text }); } : null}
                    />;
                }
            }

            title = <h2 className="bloc-title">{blocpretitle} {bloctitle}</h2>;
        }

        if (header && header.smallTitle) {
            if (typeof header.smallTitle === "string") {
                smalltitle = <h4 className="bloc-accent">
                    <AppLabel component="span" className="smallTitle text" i18n={header.smallTitle} />
                </h4>;
            } else {
                smalltitle = <h4 className="bloc-accent">
                    <DynLabel
                        component="span"
                        displayLanguage={this.props.i18n.currentLanguageCode}
                        className="smallTitle text"
                        i18n={header.title}
                        editable={this.props.editable}
                        onEdit={this.props.editable ? (text) => { this.patchHeader({ smallTitle: text }); } : null}
                    />
                </h4>;
            }
        }

        if (header && header.picture) {
            picture = <InwinkImage
                className="headerpicture"
                image={header.picture}
                event={this.props.event}
                community={this.props.community}
                i18nHelper={this.props.i18nHelper}
                defaultSize={600}
                availableSizes={[320, 600, 800]} />;
        }

        if (header && header.description) {
            if (typeof header.description === "string") {
                description = <AppLabel component="div" className="description" i18n={header.description} />;
            } else {
                description = <DynLabel
                    component="div"
                    displayLanguage={this.props.i18n.currentLanguageCode}
                    className="description"
                    i18n={header.description}
                    editable={this.props.editable}
                    onEdit={this.props.editable ? (text) => { this.patchHeader({ description: text }); } : null}
                />;
            }
        }

        if (header && header.legend) {
            if (typeof header.legend === "string") {
                legend = <AppLabel component="div" className="description legend" i18n={header.legend} />;
            } else {
                legend = <DynLabel
                    component="div"
                    displayLanguage={this.props.i18n.currentLanguageCode}
                    className="description legend"
                    i18n={header.legend}
                    editable={this.props.editable}
                    onEdit={this.props.editable ? (text) => { this.patchHeader({ legend: text }); } : null}
                />;
            }
        }

        if (!(this.props.template.properties && this.props.template.properties.hideActions)) {
            actionsItems = <BlocActions
                {...this.props}
                actions={actions}
                className="header-actions"
                editable={this.props.editable}
                onEdit={this.props.editable ? (editactions) => { this.patchAction({ actions: editactions }); } : null}
            />;
        }

        if (socialLinks) {
            let socialLinksLegend;
            if (socialLinks.legend) {
                if (typeof socialLinks.legend === "string") {
                    socialLinksLegend = <AppLabel component="div" className="sociallinkslegend" i18n={socialLinks.legend} />;
                } else {
                    socialLinksLegend = <DynLabel
                        component="div"
                        displayLanguage={this.props.i18n.currentLanguageCode}
                        className="sociallinkslegend"
                        i18n={socialLinks.legend}
                        editable={this.props.editable}
                        onEdit={this.props.editable ? (text) => { this.patchSocialLinks({ legend: text }); } : null}
                    />;
                }
            }
            socialLinksItems = <div className="bloc-social">
                {socialLinksLegend}<SocialLinks entity={socialLinks.entity} className={socialLinks.className} />
            </div>;
        }

        const classes = [(this.props.className || "bloc-header")];
        if (this.props.template?.layout?.header?.className) {
            classes.push(this.props.template?.layout?.content?.className);
        }

        if (this.props.template?.layout?.header?.position === "topflat") {
            return <header ref={this.htmlRef} className={classes.join(" ")}>
                <div className="topflat-title">
                    {icon}
                    {picture}
                    {title}
                    {this.props.children}
                    {actionsItems}
                    {socialLinksItems}
                </div>
                <div className="bloc-separator" />
                {smalltitle}
                {subtitle}
                {description}
                {legend}
            </header>;
        }

        return <header ref={this.htmlRef} className={classes.join(" ")}>
            {picture}
            {icon}
            {title}
            <div className="bloc-separator" />
            {smalltitle}
            {subtitle}
            {description}
            {legend}
            {actionsItems}
            {socialLinksItems}
            {this.props.children}
        </header>;
    }
}

// eslint-disable-next-line import/no-mutable-exports
export let BlocTitle = BlocTitleControl;
export function hookBlocTitle(callback) {
    BlocTitle = callback(BlocTitleControl);
}

export interface IBlocContentProps extends IDynamicBlocBaseProps {
    children: any;
    contentClassName?: string;
    style?: any;
    parentRef?: any;
}

export function BlocContent(props: IBlocContentProps) {
    const classes = ["bloc-content"];
    if (props.contentClassName) {
        classes.push(props.contentClassName);
    }

    if (props.template?.layout?.content?.className) {
        classes.push(props.template?.layout?.content?.className);
    }

    return <div className={classes.join(" ")} style={props.style} ref={props.parentRef}>
        {props.children}
    </div>;
}
