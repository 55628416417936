import * as React from 'react';
import * as assign from 'lodash/assign';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { BlocAnimation } from '@inwink/blocanimation';
import { AppUserRestrictedComponent } from '@@components/appuser.restricted';
import { I18NResourcesContext, labelsToResources } from '../../services/i18nservice';
import { getTrads } from '../../data/templates';
import { ContentStyle } from '../contentstyle';
import { IDynamicBlocBaseProps } from './definitions';
import { BlocActions } from './common.actions';

export type {
    IDynamicBlocBaseProps,
    IDynamicBlocProps,
    IBlocAction,
    IDynamicBlocWithProps,
    IDynamicBlocWithPropsBase
} from './definitions';

class DynamicBlocControl extends React.Component<IDynamicBlocBaseProps, any> {
    constructor(props: IDynamicBlocBaseProps) {
        super(props);
        this.state = {
            lngresources: labelsToResources(props.template && props.template.labels),
            eventtrads: props.defaultTrads ? getTrads(props.event.data) : null,
        };
    }

    componentDidUpdate(prevProps: IDynamicBlocBaseProps) {
        if (this.props.template !== prevProps.template) {
            this.setState({ lngresources: labelsToResources(this.props.template && this.props.template.labels) });
        }
    }

    blocClasses(layout: VisualTheme.IBlocContentLayout, classes: string[], prefix = "") {
        if (layout) {
            if (layout.align) {
                classes.push(prefix + "align-" + layout.align);
            }

            if (layout.valign) {
                classes.push(prefix + "valign-" + layout.valign);
            }

            if (layout.header) {
                if (layout.header.position) {
                    classes.push(prefix + "headerpos-" + layout.header.position);
                }
                if (layout.header.size) {
                    classes.push(prefix + "headersize-" + layout.header.size);
                }
                if (layout.header.valign) {
                    classes.push(prefix + "headervalign-" + layout.header.valign);
                }
            }

            if (layout.content) {
                if (layout.content.position) {
                    classes.push(prefix + "contentpos-" + layout.content.position);
                }
                if (layout.content.size) {
                    classes.push(prefix + "contentsize-" + layout.content.size);
                }
                if (layout.content.valign) {
                    classes.push(prefix + "contentvalign-" + layout.content.valign);
                }
            }

            if (layout.smartphone) {
                this.blocClasses(layout.smartphone, classes, "onsmall-");
            }
        }
        return classes;
    }

    patchAction(patch) {
        const template = assign({}, this.props.template, patch);
        this.props.patchContent(template);
    }

    patchTemplate(patch) {
        const template = assign({}, this.props.template, patch);
        this.props.patchContent(template);
    }

    visibilityChange = (isVisible) => {
        if (!this.state.isVisible) {
            this.setState({ isVisible });
        }
    };

    render() {
        const baseStyle = assign({}, this.props.rootStyles);
        const blocClasses = [this.props.className, "dynamicbloc-contentwrapper", "bloc-" + this.props.template.type];
        if (this.props.template.fullsize) {
            blocClasses.push("fullsize");
        }
        if (this.props.template.customCSSClass) {
            blocClasses.push(this.props.template.customCSSClass);
        }
        let customStyles;
        if (this.props.template.customCSS) {
            customStyles = <ContentStyle
                blocid={this.props.bloctemplate.id}
                contentid={this.props.template.id}
                css={this.props.template.customCSS}
                theme={this.props.theme}
            />;
        }

        const classes = this.blocClasses(this.props.template.layout, blocClasses);
        let customcontent;
        if (this.props.customContent) {
            customcontent = React.createElement(this.props.customContent, {
                ...this.props,
                onEdit: this.patchTemplate
            });
        }
        if (this.props.customContentCSSClass) {
            blocClasses.push(this.props.customContentCSSClass);
        }

        const wrapWithUserRestricted = (this.props.template as any).wrapWithUserRestricted;
        let actions;
        if (!(wrapWithUserRestricted || this.props.template.properties?.hideActions)) {
            actions = <BlocActions
                {...this.props}
                className="bottom-actions"
                onEdit={this.props.editable ? (a) => { this.patchAction({ actions: a }); } : null}
            />;
        }

        let content = this.props.children;
        if (wrapWithUserRestricted) {
            content = <AppUserRestrictedComponent {...this.props}>
                {this.props.children}
            </AppUserRestrictedComponent>;
        }

        return <I18NResourcesContext resources={this.props.defaultTrads}>
            <I18NResourcesContext resources={this.state.eventtrads && this.state.eventtrads.labels}>
                <I18NResourcesContext resources={this.state.lngresources}>
                    <BlocAnimation
                        id={"ct-" + this.props.template.id}
                        animation={this.props.bloctemplate?.animation?.name || ""}
                        style={baseStyle}
                        className={classes.join(" ") + (this.state.isVisible ? " visible" : "")}
                        settings={this.props.bloctemplate?.animation?.settings}
                    >
                        <div className={"dynamiccontentbloc"
                            + (this.props.contentCSSClass ? " " + this.props.contentCSSClass : "")}
                        >
                            {content}
                        </div>
                        <div className="iw-clearfix" />
                        {actions}
                        {customStyles}
                        {customcontent}
                    </BlocAnimation>
                </I18NResourcesContext>
            </I18NResourcesContext>
        </I18NResourcesContext>;
    }
}

// eslint-disable-next-line import/no-mutable-exports
export let DynamicBloc = DynamicBlocControl;
export function hookDynamicBlocContent(callback) {
    DynamicBloc = callback(DynamicBlocControl);
}
