import * as React from 'react';
import * as assign from 'lodash/assign';
import { AspectRatio } from '@inwink/responsive/aspectratio';
import { IDynamicBlocBaseProps, DynamicBloc } from '../common';
import { BlocTitle, BlocContent } from "../common.title";
import { ItemTemplate } from '../../templates/itemtemplate';
import { ContentStyle } from '../../contentstyle';
import './bloc.teaser.less';

export class BlocPictureTeaser extends React.PureComponent<IDynamicBlocBaseProps, any> {
    patchTemplate = (newtemplate) => {
        let patch = newtemplate;
        if (this.props.template.properties?.template?.template) {
            patch = assign({}, this.props.template, { template: patch });
        }
        if (this.props.template.properties?.content?.template) {
            patch = assign({}, this.props.template.properties.content, { template: patch });
        }
        if (this.props.template.properties?.template) {
            patch = assign({}, this.props.template.properties, { template: patch });
        }
        if (this.props.template.properties?.content) {
            patch = assign({}, this.props.template.properties, { content: patch });
        }

        patch = assign({}, this.props.template, { properties: patch });
        this.props.patchContent(patch);
    };

    patchData = (data) => {
        const properties = assign({}, this.props.template.properties, { data });
        const template = assign({}, this.props.template, { properties });
        this.props.patchContent(template);
    };

    render() {
        let content; let
            style;
        let template = this.props.template.properties
            && (this.props.template.properties.template || this.props.template.properties.content);
        if (template) {
            let options = null;
            if (template.template) {
                options = template.templateOptions;

                style = <ContentStyle
                    blocid={this.props.bloctemplate.id}
                    contentid={this.props.template.id}
                    css={template.customCSS}
                    theme={this.props.theme}
                />;
                template = template.template;
            }

            content = <BlocContent {...this.props}>
                <ItemTemplate
                    {...this.props}
                    user={this.props.user.currentUser}
                    data={this.props.template.properties.data}
                    datacontext={{ ...this.props.datacontext,
                        templatedata: this.props.template.properties.data,
                        templateProperties: this.props.template.properties,
                        templateOptions: options } as any}
                    template={template}
                    contentEditable={this.props.editable}
                    onEdit={this.patchTemplate}
                    onEditData={this.patchData}
                />
            </BlocContent>;
        }

        let bloctitle;
        if (!this.props.template.properties.noHeader) {
            bloctitle = <BlocTitle {...this.props} />;
        }

        if (this.props.template.properties.ratio && this.props.template.properties.ratio !== "undefined") {
            return <DynamicBloc {...this.props}>
                <AspectRatio ratio={this.props.template.properties.ratio}>
                    {(width, height) => {
                        let ratioStyle = { height: height } as any;
                        if (this.props.template.properties.ratioStyle) {
                            ratioStyle = assign({}, this.props.template.properties.ratioStyle, ratioStyle);
                        }

                        return <div style={ratioStyle}>
                            {style}
                            {bloctitle}
                            {content}
                        </div>;
                    }}

                </AspectRatio>
            </DynamicBloc>;
        }

        return <DynamicBloc {...this.props}>
            {bloctitle}
            {content}
        </DynamicBloc>;
    }
}
