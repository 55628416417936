import { BlocEventInfos } from "./blocs/bloc.eventinfos";
// import { BlocHtmlContent } from "./blocs/bloc.htmlcontent";
// import { BlocItemsList, blocItemsListData } from "./blocs/bloc.itemslist";
import { BlocMenuItems } from "./blocs/bloc.menuitems";
import { BlocPoweredBy } from "./blocs/bloc.poweredby";
import { BlocPictureTeaser } from "./blocs/bloc.teaser";
import { BlocTeaserBanner } from './blocs/bloc.teaserbanner';
import { BlocTeaserEventInfos } from "./blocs/bloc.teasereventinfos";
import { BlocTeaserLogin } from "./blocs/bloc.teaserlogin";
import { IDynamicBlocDefinitionCatalog, IDynamicBlocDefinition, registerBlocs } from './blocscatalog';

export const commonBlocs: IDynamicBlocDefinitionCatalog = {
    teaser: {
        component: BlocPictureTeaser,
        datainit() { }
    } as IDynamicBlocDefinition,
    pictureteaser: {
        component: BlocPictureTeaser,
        datainit() { }
    } as IDynamicBlocDefinition,
    teaserbanner: {
        component: BlocTeaserBanner,
        datainit() { }
    } as IDynamicBlocDefinition,
    teasereventinfos: {
        component: BlocTeaserEventInfos,
        datainit() { }
    } as IDynamicBlocDefinition,
    poweredby: {
        component: BlocPoweredBy,
        datainit() { }
    } as IDynamicBlocDefinition,
    teaserlogin: {
        component: BlocTeaserLogin,
        datainit() { }
    } as IDynamicBlocDefinition,
    menuitems: {
        component: BlocMenuItems,
        datainit() { }
    } as IDynamicBlocDefinition,
    eventinfos: {
        component: BlocEventInfos,
        datainit() { }
    } as IDynamicBlocDefinition
};

export function initCommonBlocs() {
    registerBlocs(commonBlocs);
}
