/* eslint-disable import/no-cycle */
import * as React from 'react';
import { metadataMenuActions } from '@@services/appmetadataactions/menu';
import { BlocMenuItemsControl, IBlocMenuItemsProps, IMenuItem } from './bloc.menuitems';

interface ISubMenuItemsProps extends ISubMenuItemsSectionProps {

}

export const SubMenuItems = (props: ISubMenuItemsProps) => {
    return <div className="sub-menu-items menu">
        <BlocMenuItemsControl
            {...props}
            items={props.items}
            isSubMenu={true}
            onItemClicked={props.close}
        />
    </div>;
};

interface ISubMenuItemsSectionProps extends IBlocMenuItemsProps {
    items: IMenuItem[];
    isVisible?: boolean;
    isAppHeader?: boolean;
    metaDataMenuActions?: typeof metadataMenuActions;
    close?: () => void;
}

export const SubMenuItemsSection = (props: ISubMenuItemsSectionProps) => {
    const container = React.createRef<HTMLDivElement>();
    const containerClasses = [
        "sub-menu-modal-content",
        props.isVisible ? "visible" : "hidden"
    ];

    const contentClasses = [];
    if (props.isAppHeader) {
        containerClasses.push("app-header", "bloctheme");
        // contentClasses.push("pagetitle", "withheaderitems");
    } else {
        // containerClasses.push("dynamicbloc-wrapper" /*, "bloctheme", "app-header"*/ );
        // contentClasses.push("dynamicbloc-wrapper-contentblocs");
    }
    return <div
        ref={container}
        className={containerClasses.join(" ")}
        style={props.isAppHeader ? props.visualConfiguration?.appHeader?.style : null}
    >
        <div
            className={contentClasses.join(" ")}
        >
            <SubMenuItems {...props} />
        </div>
    </div>;
};
