import * as React from 'react';
import { IDynamicBlocBaseProps } from '../common';
import { DynLabel } from '../../../services/i18nservice';
import './bloc.poweredby.less';

export class BlocPoweredBy extends React.PureComponent<IDynamicBlocBaseProps, any> {
    getUrl() {
        let url = "https://www.inwink.com";
        if (this.props.event && this.props.event.detail) {
            const eventName = this.props.event.detail.title ? encodeURI(this.props.event.detail.title) : null;
            if (eventName) {
                url += `/?utm_campaign=Referral%20Marketing%20-%20Copyright%20inwink&utm_source=${eventName}`;
            }
        }

        if (this.props.community && this.props.community.detail) {
            const communityName = this.props.community.detail.name ? encodeURI(this.props.community.detail.name) : null;
            if (communityName) {
                url += `/?utm_campaign=Referral%20Marketing%20-%20Copyright%20inwink&utm_source=${communityName}`;
            }
        }

        return url;
    }

    render() {
        let eventlogo;
        const eventlogourl = this.props.template.properties.logo;
        if (eventlogourl) {
            eventlogo = <img alt="event-logo" className="customerlogo" src={eventlogourl} />;
        }
        let label = <div className="label">Powered by inwink</div>;
        if (this.props.template.properties.label) {
            label = <DynLabel component="div" className="label" i18n={this.props.template.properties.label} />;
        }
        return <div className="bloc-poweredby">
            <a href={this.getUrl()} target="blank">
                {eventlogo}
                {label}
                <i className="icon inwink-inwink-logo" />
            </a>
        </div>;
    }
}
