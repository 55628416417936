/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { Link } from 'react-router-dom';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import type { VisualTheme } from '@inwink/entities/visualtheme';
import type { Entities } from '@inwink/entities/entities';
import { getPredicate } from '@inwink/expressions';
import { getTargetPage } from '../navigation';
import { AppLink, protocolRegEx } from '../applink';
import type { States } from '../../services/services';
import { displayContent } from '../../services/itemshelpers';
import type { IInwinkStore } from '../../store';
import { checkIfShownByDate } from './helpers';
import type { IDynamicBlocBaseProps, IBlocAction } from './definitions';
import { useStore } from 'react-redux';

const BadgeDownload = React.lazy(() => import("../badgedownload"));
const EntityDetailActions = React.lazy(() => import(
    "./entitydetail/entitydetail.actions"
));
const CalendarEventGenerator = React.lazy(() => import(
    "@@event/components/calendargenerator.event"
));

export interface IBlocActionsComponentProps extends IDynamicBlocBaseProps {
    store?: IInwinkStore;
    editable?: boolean;
    onEdit?: (actions: any) => void;
    callbackActionClicked?: (action: VisualTheme.IBlocTemplateAction) => void;
    i18nHelper?: Entities.i18nHelper;
}
export interface IBlocActionsComponentState {
    initialized: boolean;
}
@withI18nHelper()
class BlocActionsComponent extends React.Component<IBlocActionsComponentProps, IBlocActionsComponentState> {
    constructor(props: IBlocActionsComponentProps) {
        super(props);
        this.state = {
            initialized: false
        };
    }

    componentDidMount(): void {
        if (!this.state.initialized){
            this.setState({ initialized : true });
        }
    }

    getTargetPage(link: VisualTheme.IAppLink, event: States.IEventState, context: any): any {
        const linkTarget = getTargetPage(
            link,
            context,
            (this.props as any).store
        );
        if (typeof linkTarget === "string") return this.props.urlservice.pageUrl(linkTarget);

        return linkTarget;
    }

    private filterActionItem(i, event: States.IEventState, datacontext: Entities.IPageDataContext) {
        const item = i;
        let show: any = true;
        if (item.disabled) {
            return false;
        }

        if (item.showIf) {
            if (!(item as any).showIfPredicate) {
                const pr = getPredicate(item.showIf);
                (item as any).showIfPredicate = pr;
            }
            show = (item as any).showIfPredicate(this.props.datacontext);
        }

        if (item.showblocbydate && event.detail && !InWinkPreview && show) {
            show = checkIfShownByDate(item, event, datacontext);
        }

        return show;
    }

    render() {
        let actionsElts;
        const i18n = this.props.i18nHelper;
        const actions = !this.props.disableActions && (this.props.actions || this.props.template.actions);
        if (actions && actions.length) {
            actionsElts = actions.filter((a) => displayContent(this.props.user, {
                event: this.props.event,
                community: this.props.community,
                requireConnexion: a.requireConnexion,
                requireRegistration: a.requireRegistration,
                requireMembership: a.requireMembership,
                membershipActive: a.membershipActive,
                membershipLevelIds: a.membershipLevelIds
            })
                && this.filterActionItem(a, this.props.event, this.props.datacontext)).map((aa: IBlocAction, i) => {
                const className = [];
                const a = aa;
                let title;
                const bag = a.label || a.title;
                let text: any = !bag && a.i18nLabel
                    ? i18n.translate(a.i18nLabel, null, a.i18nLabel)
                    : i18n.translateBag(bag);
                let child = text;
                const icon = a.icon && a.icon.className ? <i className={"icon " + a.icon.className} /> : undefined;
                if (a.showblocbydate && InWinkPreview) {
                    const filterType = checkIfShownByDate(a, this.props.event, this.props.datacontext, true);
                    // TODO : Refaire la méthode checkIfShowByDate
                    const filterIsNotBool = typeof(filterType) !== "boolean";
                    if (filterIsNotBool) {
                        className.push("hidden");
                        if ((filterType as any).dates) {
                            title = i18n.translate("actions.filterbydate.dates", { dates: (filterType as any).dates });
                        } else {
                            title = i18n.translate(`actions.filterbydate.${filterType}`);
                        }
                    }
                }
                if (this.props.editable) {
                    const props: any = {};
                    props["data-texteditable"] = 1;
                    props.contentEditable = true;
                    props.style = {
                        padding: "7px 14px",
                        margin: "-7px -14px"
                    };
                    props.suppressContentEditableWarning = true;
                    props.onClick = (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    };

                    props.onBlur = () => {
                        if (this.props.onEdit) {
                            // eslint-disable-next-line react/no-find-dom-node
                            const elt = ReactDOM.findDOMNode(this) as HTMLInputElement;
                            const newtxt = elt.querySelector('[data-texteditable]').innerHTML;
                            if (newtxt !== text) {
                                const lng = this.props.i18n.currentLanguageCode;
                                if (a.label) {
                                    a.label[lng] = newtxt;
                                } else {
                                    a.title[lng] = newtxt;
                                }
                                this.props.onEdit(actions);
                                text = newtxt;
                            }
                        }
                    };
                    child = <span {...props}>{text}</span>;
                }
                if (a.displayOptions) {
                    if (a.displayOptions.isSecondary) {
                        className.push("lightbtn");
                    }
                }
                if (a?.viewports?.length) {
                    className.push("has-viewport");
                    a.viewports.forEach((viewport) => {
                        className.push(`display-on-${viewport}`);
                    });
                }
                if (a.type === 'checkbox') {
                    const isChecked = !!(a.value && a.value.toString() === 'true');
                    return <div key={'cbk' + i}>
                        <br /> <input
                            id={a.id}
                            type="checkbox"
                            autoComplete="off"
                            checked={isChecked}
                            title={title}
                            onChange={(arg) => this.props.onChecked(a.id, arg)}
                        /> <span> {text}</span>
                    </div>;
                }

                if (a.link && typeof a.link !== 'string' && a.link.url) {
                    return <AppLink
                        role="button"
                        key={'a.link' + i}
                        className={className.join(" ")}
                        link={a.link}
                        user={this.props.user}
                        i18n={this.props.i18n}
                        datacontext={this.props.datacontext}
                        title={title}
                        onClick={this.props.callbackActionClicked ? () => this.props.callbackActionClicked(a) : undefined}
                    >
                        {icon}
                        {child}
                    </AppLink>;
                }

                if (a.callback) {
                    return <AsyncButton
                        key={'a.link' + i}
                        onClick={a.callback}
                        i18nLabel={a.i18nLabel}
                        title={title}
                        className={className.join(' ')}
                    >
                        {icon}
                        {child}
                    </AsyncButton>;
                }
                if (this.props.onActionsClick) {
                    return <AsyncButton
                        key={'a.link' + i}
                        onClick={this.props.onActionsClick[i]}
                        title={title}
                        className={className.join(' ')}
                    >
                        {icon}
                        {child}
                    </AsyncButton>;
                }
                if (this.props.onActionClick) {
                    return <AsyncButton
                        key={'a.link' + i}
                        onClick={this.props.onActionClick as any}
                        title={title}
                        className={className.join(' ')}
                    >                        
                        {icon}
                        {child}
                    </AsyncButton>;
                }
                let target = null;
                if (this.props.urlservice.isWidget) {
                    target = "_blank";
                }

                if (typeof a.link === "string") {
                    let targeturl: ((event: any) => void) | string;
                    targeturl = this.getTargetPage({ target: a.link }, this.props.event, this.props.page.context);
                    if (targeturl) {
                        if (typeof targeturl === "string") {
                            if (a.params && Array.isArray(a.params) && a.params.length > 0) {
                                targeturl += (targeturl.indexOf('?') < 0 ? '?' : '&');

                                a.params.forEach((param) => {
                                    targeturl = targeturl + param + '&';
                                });

                                targeturl = targeturl.substring(0, targeturl.length - 1);
                            }

                            if (protocolRegEx.test(targeturl)) {
                                return <a
                                    role="button"
                                    key={'a.link' + i}
                                    href={targeturl}
                                    target={target}
                                    title={title}
                                >{child}</a>;
                            }
                            return <Link
                                role="button"
                                key={'a.link' + i}
                                to={targeturl}
                                target={target}
                                title={title}
                            >{child}</Link>;
                        }
                        /* eslint-disable */
                        return <a
                            role="button"
                            key={'a.link' + i}
                            onClick={targeturl}
                            target={target}
                            title={title}
                        >{child}</a>;
                        /* eslint-enable */
                    }
                } else {
                    if ((a.link && a.link.action === "addEventToCalendar")
                        || (a.link && a.link.action === "addSessionsToCalendar")) {
                        if (!this.state.initialized) {
                            return <React.Fragment key={'a.link' + i} />;
                        }
                        return <React.Suspense key={'a.link' + i} fallback={<EmptyLink />}>
                            <CalendarEventGenerator
                                className={className.join(" ")}
                                role="button"
                                key={'cal' + i}
                                page={this.props.page}
                                items={this.props.blocState?.items}
                                title={a.label}
                                type={a.link.action}
                                i18n={this.props.i18n}
                                event={this.props.event}
                                tooltip={title}
                                icon={icon}
                            />
                        </React.Suspense>;
                    } if (a.link && a.link.action && a.link.action === "downloadBadge") {
                        if (!this.state.initialized) {
                            return <React.Fragment key={'a.link' + i} />;
                        }
                        return <React.Suspense key={'a.link' + i} fallback={<EmptyLink />}>
                            <BadgeDownload
                                className={className.join(" ")}
                                role="button"
                                key={'cal' + i}
                                title={a.label}
                                type={a.link.action}
                                i18n={this.props.i18n}
                                tooltip={title}
                            />
                        </React.Suspense>;
                    } if (a.link?.action && this.props.template.type === "entitydetailv2") {
                        if (!this.state.initialized) {
                            return <React.Fragment key={'a.link' + i} />;
                        }
                        return <React.Suspense key={'a.link' + i} fallback={<EmptyLink />}>
                            <EntityDetailActions
                                {...this.props}
                                key={"a.link" + i}
                                actionClassName={className.join(" ")}
                                page={this.props.page}
                                i18nHelper={i18n}
                                i18n={this.props.i18n}
                                entityId={this.props.page?.context?.entityid}
                                user={this.props.user}
                                event={this.props.event}
                                entityKind={this.props.page?.context?.entityKind}
                                action={a}
                                title={title}
                            />
                        </React.Suspense>;
                    }
                    return <AppLink
                        role="button"
                        key={'a.link' + i}
                        className={className.join(" ")}
                        link={a.link}
                        user={this.props.user}
                        i18n={this.props.i18n}
                        datacontext={this.props.datacontext}
                        title={title}
                        onClick={this.props.callbackActionClicked ? () => this.props.callbackActionClicked(a) : undefined}
                    >
                        {icon}
                        {child}
                    </AppLink>;
                }
                return null;
            });
        } else {
            return null;
        }

        return <div className={"bloc-actions " + (this.props.className || '')
            + (this.props.actions?.length > 1 ? " multiple-actions" : "")}
        >
            {actionsElts}
        </div>;
    }
}

export function BlocActions(props: IBlocActionsComponentProps) {
    const store = useStore() as any;
    return <BlocActionsComponent {...props} store={store} />;
}

function EmptyLink() {
    return <a />;
}
