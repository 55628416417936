import { VisualTheme } from '@inwink/entities/visualtheme';
import { loadModule } from '../../routes/appmodules';
import { States } from '../../services/services';

export interface IDynamicBlocDefinitionCatalog {
    [name: string]: IDynamicBlocDefinition
}

export type IBlocDataInitCallback = (
    page: States.ICurrentPageState,
    blocdata: any,
    bloc: VisualTheme.IBlocContentTemplateBase,
    data: States.IAppState,
    dispatch: (action) => void,
    isRefresh: boolean
) => any;

export interface IDynamicBlocDefinition {
    component: any;
    headerComponents?: any[];
    footerComponent?: any;
    customCSSClass?: string;
    customPageCSSClass?: string;
    datainit?: IBlocDataInitCallback;
}

export const blocsCatalog: IDynamicBlocDefinitionCatalog = {};

export function registerBloc(name: string, blocdefinition: IDynamicBlocDefinition) {
    blocsCatalog[name] = blocdefinition;
}

export function registerBlocs(blocs: IDynamicBlocDefinitionCatalog) {
    Object.assign(blocsCatalog, blocs);
}

export interface IBlocConditions {
    mustBeLogged?: boolean;
    mustBeRegistered?: boolean;
}

// const logged: IBlocConditions = {
//     mustBeLogged: true
// }

const loggedAndRegistered: IBlocConditions = {
    mustBeLogged: true,
    mustBeRegistered: true
};

export const asyncBlocsConditions: Record<string, IBlocConditions> = {
    myevent: loggedAndRegistered,
    allattendees: loggedAndRegistered,
    // attendeeslist: loggedAndRegistered,
    contacts: loggedAndRegistered,
    discussionthread: loggedAndRegistered
};

export interface IAsyncBlocConstraints {
    module?: string;
    promise?: () => Promise<any>;
    user?: boolean;
    registered?: boolean;
    allowNetworking?: boolean;
}

export type IAsyncBlocCallback = (dispatch, getState) => Promise<any>;
export const asyncBlocs: Record<string, string | IAsyncBlocConstraints> = {
    teaserevents: "mod-rootwebsite",
    onboarding: "mod-control-onboarding",
    picturegallery: "mod-control-picturegalery",
    plugin: "mod-control-plugin",
    qrcode: "mod-control-qrcode",
    addapptohomescreen: "mod-control-addapptohomescreen",
    entitytags: "mod-control-entitytags",
    entitydetail: "mod-entitydetail",
    entitydetailv2: "mod-entitydetailv2",
    video: "mod-control-video",
    linkstoolbar: "mod-control-linkstoolbar",
    htmlcontent: "mod-control-htmlbloc",
    itemslist: "mod-control-itemslist",
    videoslist: "mod-control-itemslist"
};

const blocsLoading = {};

export const loadBlocsActions = {
    loadAsyncBloc: (type: string) => {
        return (dispatch, getState) => {
            if (blocsLoading[type]) {
                return blocsLoading[type];
            }

            const info = asyncBlocs[type];
            const module = typeof info === "string" ? info : info.module;
            const p = loadModule(module, dispatch, getState);
            blocsLoading[type] = p;
            return p;
        };
    }
};
