import * as React from 'react';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import { withRouter } from 'react-router-dom';
import { DynLabel } from '../../../services/i18nservice';
import { IDynamicBlocBaseProps, DynamicBloc } from '../common';
import { AppLink } from "../../applink";
import { withUrlService } from "../../urlstatecontext";
import './bloc.teaserlogin.less';

interface ITeaserLoginProps extends IDynamicBlocBaseProps {
    message?: string;
    history?: any;
}
@withUrlService()
class BlocTeaserLoginComponent extends React.PureComponent<ITeaserLoginProps, any> {
    renderRegistrationBtn() {
        const registration = this.props.event.detail?.configuration?.registration;
        if (registration && registration.defaultRegistrationLink) {
            return <AppLink
                link={registration.defaultRegistrationLink}
                i18n={this.props.i18n}
                role="button"
            >
                <AppTextLabel i18n="action.requireregistration.button" />
            </AppLink>;
        } if (registration && registration.defaultRegistrationPage) {
            // old way
            return <button
                type="button"
                onClick={() => {
                    this.props.history.push(this.props.urlservice.pageUrl(registration.defaultRegistrationPage));
                }}
            ><AppTextLabel i18n="action.requireregistration.button" /></button>;
        }
    }

    render() {
        let label = <div className="label">Register Now</div>;
        let icon;
        if (this.props.template.properties.label) {
            label = <DynLabel component="div" className="label" i18n={this.props.template.properties.label} />;
        }
        if (this.props.template.properties.icon) {
            icon = <i className={this.props.template.properties.icon} />;
        }

        return <DynamicBloc {...this.props} urlservice={this.props.urlservice}>
            <div className="bloc-teaserlogin">
                <div className="teaserlogin-content">
                    {icon}
                    {label}
                </div>
                <div className="actions">
                    {this.renderRegistrationBtn()}
                </div>
            </div>
        </DynamicBloc>;
    }
}

export const BlocTeaserLogin: new (props: ITeaserLoginProps)
=> React.Component<ITeaserLoginProps, any> = withRouter(BlocTeaserLoginComponent as any) as any;
