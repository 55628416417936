import { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import { States } from '../../services/services';
import { getTimeZoneDate, getTimeZoneDateDisplay } from '../displaytimezonedate';

export function isFromServer() {
    const isFromSsr = __SERVERSIDE__
        || !!((global as any).INITIAL_STATE)
        || !!((global as any).inwinkEventBootstrap)
        || !!((global as any).inwinkCommunityBootstrap)
        || !!((global as any).inwinkWebsiteBootstrap);

    return isFromSsr;    
}

export function checkIfShownByDate(item, event: States.IEventState, datacontext: Entities.IPageDataContext, explicit?: boolean) {
    let currDate;
    if (!event || !event.detail) {
        return true;
    }

    if (event.detail && event.detail.fakeDate) {
        currDate = moment(event.detail.customDate || event.detail.fakeDate);
    } else {
        currDate = moment();
    }
    const eventStartDate = getTimeZoneDate(event?.detail?.startDate as string, event?.detail, datacontext?.i18nstate);
    const eventEndDate = getTimeZoneDate(event?.detail?.endDate as string, event?.detail, datacontext?.i18nstate);
    // let customDate = moment(this.props.event.detail.customDate);
    const customStartDate = getTimeZoneDate(item.customStartDate, event?.detail, datacontext?.i18nstate);
    const customEndDate = getTimeZoneDate(item.customEndDate, event?.detail, datacontext?.i18nstate);
    if (item.showblocbydate === "0") {
        if (moment(currDate).isBefore(eventStartDate)) {
            return !explicit;
        }

        if (explicit) {
            return "before";
        }
        return false;
    }
    if (item.showblocbydate === "1") {
        if (moment(currDate).isSameOrAfter(eventStartDate) && moment(currDate).isSameOrBefore(eventEndDate)) {
            return !explicit;
        }

        if (explicit) {
            return "during";
        }
        return false;
    }
    if (item.showblocbydate === "2") {
        if (moment(currDate).isAfter(eventEndDate)) {
            return !explicit;
        }

        if (explicit) {
            return "after";
        }
        return false;
    }
    if (item.showblocbydate === "3") {
        return true;
    }
    if (item.showblocbydate === "4") {
        if (moment(currDate).isSameOrAfter(customStartDate) && moment(currDate).isSameOrBefore(customEndDate)) {
            return !explicit;
        }

        if (explicit) {
            return {
                dates:
                    // eslint-disable-next-line max-len
                    `${getTimeZoneDateDisplay(customStartDate, event.detail, datacontext.i18nstate)} - ${getTimeZoneDateDisplay(customEndDate, event.detail, datacontext.i18nstate)}` };
        }
        return false;
    }

    return true;
}
